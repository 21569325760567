import React, { createContext, useContext } from "react";
import useAppIOSConnection, {
  Response,
  UseAppIOSConnection,
} from "./useAppIOSConnection";

const AppIOSConnectionContext = createContext<{
  requestPhotoDataAtNeptune(id: string, data: any): boolean;
  receivedPhotoData: Response;
  clear(): void;
}>({} as any);

interface AppIOSConnectionProviderProps extends UseAppIOSConnection {
  children: any;
}

export const AppIOSConnectionProvider: React.FC<
  AppIOSConnectionProviderProps
> = ({ children, ...other }) => {
  const state = useAppIOSConnection(other);

  return (
    <AppIOSConnectionContext.Provider value={state}>
      {children}
    </AppIOSConnectionContext.Provider>
  );
};

export const useAppIOSConnectionProvider = () => {
  return useContext(AppIOSConnectionContext);
};
