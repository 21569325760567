import { useBoolean } from "@chakra-ui/react";
import { message } from "components/base";
import { useAppIOSConnectionProvider } from "pages/forge-viewer/context/AppIosConnection";
import { useCallback, useEffect, useRef } from "react";
import { base64ToFile } from "utils/file";

const useCamera = ({
  onReceived,
  identifyInfo,
  id,
}: {
  onReceived(file: File): void;
  identifyInfo: any;
  id: string;
}) => {
  const [isOpenCamera, setIsOpenCamera] = useBoolean();
  const isOpening = useRef(false);
  isOpening.current = isOpenCamera;
  const { requestPhotoDataAtNeptune, receivedPhotoData, clear } =
    useAppIOSConnectionProvider();

  useEffect(() => {
    if (receivedPhotoData?.id === id) {
      try {
        const timeout = setTimeout(() => {
          onReceived(
            base64ToFile(
              receivedPhotoData.data?.data,
              `${new Date().getTime()}.jpg`
            )
          );
          clear();
        }, 500);

        return () => {
          clearTimeout(timeout);
        };
      } catch (error: any) {
        message.error(["Error when parse raw data to json"]);
        throw new Error(error);
      }
    }
  }, [clear, id, onReceived, receivedPhotoData]);

  const handleOpenCamera = useCallback(() => {
    const isRequested = requestPhotoDataAtNeptune(id, identifyInfo);
    if (!isRequested) {
      // Show camera if not connect to a device
      setIsOpenCamera.on();
    }
  }, [id, identifyInfo, requestPhotoDataAtNeptune, setIsOpenCamera]);

  const handleCloseCamera = useCallback(() => {
    setIsOpenCamera.off();
  }, [setIsOpenCamera]);

  return {
    isOpenCamera,
    handleOpenCamera,
    handleCloseCamera,
  };
};

export default useCamera;
