import { listOtherPartnerCompanies } from "constants/blackBoardTemplate";
import { useAuthorization } from "hooks/usePermission";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { Level } from "interfaces/models";
import { Blackboard } from "interfaces/models/blackboard";
import { DataLog } from "interfaces/models/dataLog";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User } from "interfaces/models/user";
import { getDataBlackboardDefault } from "models/document";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import ModalTypePin from "./ModalTypePin";
import ModalTypeSubItem from "./ModalTypeSubItem";
import { useAppIOSConnectionProvider } from "pages/forge-viewer/context/AppIosConnection";

interface Props {
  documentCategories: DocumentCategoryDTO[];
  documentCategorySelected?: DocumentCategoryDTO;
  documentItemSelected?: DocumentItemDTO;
  documentTemplate?: DocumentTemplate;
  familyInstance: FamilyInstanceDTO;
  itemStatusColor: string;
  levelSelected?: Level;
  projectName?: string;
  isLoadedBlackboard: boolean;
  dataBlackBoards: Blackboard[];
  isOnline: boolean;
  itemDisplayOrder: number | string;
  dataLogTemp: DataLog | undefined;

  isLoadedViewer: boolean;
  partnerCompanies?: PartnerCompany[];
  users?: UserDTO[];
  currentUser?: User | null;
  levels?: Level[];
  listAllUserById: { [key: string]: UserDTO };
  listUserById: { [key: string]: UserDTO };
  fromExternalLogs: DataLog[] | undefined;

  clearFromExternalLogs: () => void;
  onChangePhotoLedgerModalType: (value: PhotoLedgerModalType) => void;
}

export enum PhotoLedgerModalType {
  PIN = "pin",
  SUB = "sub",
}

const FormItemPhotoLedger = (props: Props) => {
  const {
    documentCategories,
    documentTemplate,
    documentItemSelected,
    documentCategorySelected,
    levels = [],
    levelSelected,
    currentUser,
    users = [],
    partnerCompanies = [],
    isLoadedViewer,
    familyInstance,
    itemStatusColor,
    projectName,
    isLoadedBlackboard,
    dataBlackBoards,
    isOnline,
    itemDisplayOrder,
    listAllUserById,
    dataLogTemp,
    listUserById,
    fromExternalLogs,

    clearFromExternalLogs,
    onChangePhotoLedgerModalType,
  } = props;

  const [modalType, setModalType] = useState<PhotoLedgerModalType>(
    PhotoLedgerModalType.PIN
  );
  const [subItemSelected, setSubItemSelected] = useState<DocumentSubItemDTO>();
  const { canEditDocumentItem } = useAuthorization();
  const { receivedPhotoData } = useAppIOSConnectionProvider();

  useEffect(() => {
    if (!receivedPhotoData) return;
    const { decrypted } = receivedPhotoData;
    if (
      decrypted?.documentCategoryId === documentCategorySelected?.id &&
      decrypted?.documentItemId === documentItemSelected?.id
    ) {
      const timeout = setTimeout(() => {
        const subItem = documentItemSelected?.subItems?.find(
          (subItem) => subItem.id === decrypted?.subItemId
        );

        if (subItem) {
          setSubItemSelected(subItem);
          setModalType(PhotoLedgerModalType.SUB);
        }

        // Wait for right sidebar display
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [receivedPhotoData, documentCategorySelected, documentItemSelected]);

  const handleCloseModalSubItem = useCallback(() => {
    setModalType(PhotoLedgerModalType.PIN);
    setSubItemSelected(undefined);
  }, []);

  const handleSelectSubItem = useCallback((subItem: DocumentSubItemDTO) => {
    setModalType(PhotoLedgerModalType.SUB);
    setSubItemSelected(subItem);
  }, []);

  useEffect(() => {
    onChangePhotoLedgerModalType(modalType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType]);

  useEffect(() => {
    const subItem = documentItemSelected?.subItems?.find(
      (i) => i.id === subItemSelected?.id
    );
    if (!subItem) {
      setSubItemSelected(undefined);
      setModalType(PhotoLedgerModalType.PIN);

      return;
    }

    setSubItemSelected(subItem);
  }, [documentItemSelected, subItemSelected?.id]);

  const dataBlackboardDefault = useMemo(
    () =>
      getDataBlackboardDefault({
        levels: levels || [],
        currentUser: currentUser as any,
        users: users || [],
        partnerCompanies,
        currentLevel: documentItemSelected?.level,
      }),
    [documentItemSelected?.level, currentUser, users, levels, partnerCompanies]
  );

  const listOptionFloor: { label: string; value: string }[] = useMemo(() => {
    return levels
      ?.filter((level) => !!level.guid)
      ?.map((item) => {
        return {
          value: String(item?.guid),
          label: String(item?.label),
        };
      });
  }, [levels]);

  const listOptionUsers: { label: string; value: string }[] = useMemo(() => {
    return Object.values(listUserById)
      ?.map((user) => ({
        label: user?.name || "",
        value: user?.id || "",
      }))
      ?.filter((user) => !!user?.value);
  }, [listUserById]);

  const listPartnerCompanies: { label: string; value: string }[] =
    useMemo(() => {
      return [
        ...listOtherPartnerCompanies,
        ...(partnerCompanies
          ?.map((company) => ({
            label: company.name || "",
            value: company.id || "",
          }))
          ?.filter((company) => !!company.value) || []),
      ];
    }, [partnerCompanies]);

  if (modalType === "pin") {
    return (
      <ModalTypePin
        isOnline={isOnline}
        documentTemplate={documentTemplate}
        familyInstance={familyInstance}
        isLoadedViewer={isLoadedViewer}
        documentItemSelected={documentItemSelected}
        documentCategorySelected={documentCategorySelected}
        itemStatusColor={itemStatusColor}
        isDisabled={!canEditDocumentItem}
        itemDisplayOrder={itemDisplayOrder}
        documentCategories={documentCategories}
        dataLogTemp={dataLogTemp}
        fromExternalLogs={fromExternalLogs}
        clearFromExternalLogs={clearFromExternalLogs}
        onSelectSubItem={handleSelectSubItem}
      />
    );
  }

  return (
    <ModalTypeSubItem
      users={listAllUserById}
      subItemSelected={subItemSelected}
      documentTemplate={documentTemplate}
      documentItemSelected={documentItemSelected}
      documentCategorySelected={documentCategorySelected}
      dataBlackboardDefault={dataBlackboardDefault}
      itemStatusColor={itemStatusColor}
      familyInstance={familyInstance}
      levelSelected={levelSelected}
      listOptionUsers={listOptionUsers}
      listOptionFloor={listOptionFloor}
      listPartnerCompanies={listPartnerCompanies}
      projectName={projectName}
      isLoadedBlackboard={isLoadedBlackboard}
      dataBlackBoards={dataBlackBoards}
      itemDisplayOrder={itemDisplayOrder}
      isDisabled={!canEditDocumentItem}
      onClose={handleCloseModalSubItem}
    />
  );
};

export default memo(FormItemPhotoLedger);
